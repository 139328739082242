import BigImageView from "../../components/BigImageView";
import PageHeader from "../../components/PageHeader";
import { illustrationImages as images  }  from "../../images";
const Illustration = () => {
return (
    <section className="illustration">
      <PageHeader title="illustration.title" description="illustration.description" />
      <BigImageView images={images}/>
    </section>
  );
};

export default  Illustration;
