import NavLinks from "./NavLinks";
import {FormattedMessage} from 'react-intl';
import { Link, useParams } from "react-router-dom";
import signature from "../images/signature.svg";

const Header = () => {
  const { lang } = useParams();
  const homeref = '/' + lang;
  return (
    <header className="header">
      <span><Link key="home" to={homeref}><FormattedMessage id="toolbar.brand" description="Sasha"
	  values={{
	  	nick: (name)=>(
			<span className="nickname">{name}</span>
		)
	  }}/></Link>
	  <img className="logo" src={signature} alt="Alexandra Sign"/>
	  </span>
      <NavLinks />
    </header>
  );
};

export default Header;
