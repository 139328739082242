import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const BigImageView = ( { images } ) => {
	let { id } = useParams();
	const navigate = useNavigate();
	let basepath = useLocation().pathname;
	var index = -1;
	if ( id !== undefined ) {
		index = images.findIndex( e=>e.src===id );
		basepath = basepath.substr(0,basepath.lastIndexOf('/'));
	}
	
	const section = basepath.substr(basepath.lastIndexOf('/')+1);
	const imgs = images.map( i => { return { width: i.width, height: i.height
		, src:'/img/'+section+'/'+i.src} } );

	const currentImage = index===-1 && id!==undefined?{src:'/img/'+section+'/' +id }: imgs[index];
	const nextIndex = index===-1?-1:(index + 1) % images.length;
	const nextImage = index===-1?currentImage:imgs[nextIndex];
	const prevIndex = index===-1?-1:(index + images.length - 1) % images.length;
	const prevImage = index===-1?currentImage:imgs[prevIndex];

	const getId = i=>i.src.substr(i.src.lastIndexOf('/')+1);
	const handleClick = ( index, item ) => navigate(basepath + '/' + getId(item) );
	const handleClose = () => navigate(basepath);
	
	const handleMovePrev = () => navigate(basepath+ '/' + getId(prevImage));
	const handleMoveNext = () => navigate(basepath+ '/' + getId(nextImage));

	return <div>
		<Gallery
	images={imgs}
	onClick={handleClick}
	enableImageSelection={false}
		/>
		      {!!currentImage && (
			              /* @ts-ignore */
			              <Lightbox
			                mainSrc={currentImage.src}
			                imageTitle={currentImage.caption}
			                mainSrcThumbnail={currentImage.src}
			                nextSrc={nextImage.src}
			                nextSrcThumbnail={nextImage.src}
			                prevSrc={prevImage.src}
			                prevSrcThumbnail={prevImage.src}
			                onCloseRequest={handleClose}
			                onMovePrevRequest={handleMovePrev}
			                onMoveNextRequest={handleMoveNext}
			              />
			            )}
	    </div>;

};

export default BigImageView;
