import PageHeader from "../../components/PageHeader";
import {FormattedMessage as F} from 'react-intl';

import './About.css';

const makeLink = ( chunk, link ) => (<a href={link}
	                                                className="external_link"
	                                                target="_blank" rel="noreferrer">{chunk}</a>);
const About = () => {
  return (
    <section className="about">
      <PageHeader title="about.me" description="about.description" />
	  <div className="row">
	  <div className="column aboutPhotoContainer">
	  <div className="aboutPhoto">
	  </div>
	  </div>
	  <div className="column aboutText">
		<p><F id="about.text1"/></p>
		<p><F id="about.text2"
	  		values={{
				zdrava : chunk=>makeLink(chunk,"https://vk.com/zdrava_comics")
				,album : chunk=>makeLink(chunk,"https://vk.com/album-213472418_287659155")
				,spag: chunk=>makeLink(chunk,"https://acomics.ru/-Spaghettiheaded")
			}}
	  	/></p>
		<p><F id="about.text3"
	  		values={{
				mail: chunk=>(
					<a href="mailto:me@sashulya.ru">{chunk}</a>
				)
			}}
	  	/></p>
	  	<br/><br/>
	  </div>
	  </div>
    </section>
  );
};

export default About;
