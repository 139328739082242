import { Route, Navigate } from "react-router-dom";

import Home from "../pages/landing/Landing";
import About from "../pages/about/About";
import Design from "../pages/design/Design";
import Illustration from "../pages/illustration/Illustration";
import App from "../App";
import BigImageView from "../components/BigImageView";

import { isLanguageSupported } from '../languages';
import { designImages,illustrationImages } from '../images';
const checkLanguage = ({ params } ) =>{
	if ( !isLanguageSupported( params.lang ) ) {
		throw new Error("Invalid language:" + params.lang);
	}
	return null;
}

const LanguageRouter = ({ initialLanguage }) => {
return ( 
	  <Route path="/">
	    <Route index element={<Navigate to={initialLanguage} replace="true" />} />
	    <Route path=":lang" loader={checkLanguage} element={<App/>} >
		<Route index element={<Home/>} />
	    	<Route path="about" element={<About/>} />
	    	<Route path="illustration" element={<Illustration/>} />
	    	<Route path="illustration/:id" element={<BigImageView images={illustrationImages}/>} />
	    	<Route path="design" element={<Design/>} />
	    	<Route path="design/:id" element={<BigImageView images={designImages}/>} />
	    </Route>
	</Route>
);
};
export default LanguageRouter;
