import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LanguageRouter from "./components/LanguageRouter";
import { getUserLanguage } from './languages';

import { createBrowserRouter, RouterProvider,createRoutesFromElements} from "react-router-dom";
const root = document.getElementById('root');


const router = createBrowserRouter(  createRoutesFromElements( 
	LanguageRouter( { initialLanguage: getUserLanguage() } )
	) );

ReactDOM.render(
  <React.StrictMode>
	<RouterProvider router={router} />
  </React.StrictMode>
	, root
);

