
import PageHeader from "../../components/PageHeader";
import { designImages as images  }  from "../../images";
import BigImageView from "../../components/BigImageView";

const Design = () => {
  return ( 
    <section className="design">
      <PageHeader title="design.title" description="design.description" />
     <BigImageView images={images}/>
    </section>
  );
};

export default Design;
