import { useState } from "react";
import { useParams } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";

import openMenu from "../images/open.svg";
import closeMenu from "../images/close.svg";
import {FormattedMessage as F} from 'react-intl';
import { languagesSupported } from "../languages";

const NavLinks = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { lang } = useParams();
  const location = useLocation().pathname;
  const locations = languagesSupported.filter(l=>l!==lang).map( l => '/' + l + location.substr(3));

  return (
    <>
      <button className="dropdown-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? (
          <img className="closeMenu" src={closeMenu} alt="Close" />
        ) : (
          <img className="openMenu" src={openMenu} alt="Open" />
        )}
      </button>
      <nav className={`links ${isMenuOpen ? "open" : "closed"}`}>
        <NavLink key="about" to="about" onClick={() => setIsMenuOpen(false)}>
          <F id="toolbar.about" description="About"/>
        </NavLink>
        <NavLink key="illustration" to="illustration" onClick={() => setIsMenuOpen(false)}>
          <F id="toolbar.illustration" description="Inllustration"/>
        </NavLink>
        <NavLink key="design" to="design" onClick={() => setIsMenuOpen(false)}>
          <F id="toolbar.design" description="Design"/>
        </NavLink>
	  { locations.map( l=><NavLink key={l} to={l} onClick={() => setIsMenuOpen(false)}>{l.substr(1,2)}</NavLink>)
	  }
      </nav>
    </>
  );
};

export default NavLinks;
