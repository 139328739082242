
const designImages = [

	{src:"des1.png", width:3221,height:3129 }
	,{src:"des2.png", width:901,height:1601 }
	,{src:"des3.gif", width:581,height:327 }
	,{src:"des4.png", width:1200,height:600 }
	,{src:"des21.png", width:654,height:1086 }
	,{src:"des5.png", width:1685,height:1191 }
	,{src:"des6.png", width:1500,height:875 }
	,{src:"des7.png", width:1600,height:900 }
	,{src:"des8.png", width:900,height:900 }
	,{src:"des9.png", width:2734,height:1306 }
	,{src:"des10.png", width:3562,height:4000 }
	,{src:"des11.png", width:1622,height:1336 }
	,{src:"des12.png", width:2000,height:3000 }
	,{src:"des13.png", width:1047,height:765 }
	,{src:"des14.png", width:2422,height:910 }
	,{src:"des15.png", width:759,height:966 }
	,{src:"des16.png", width:3183,height:4910 }
	,{src:"des17.png", width:2250,height:1135 }
	,{src:"des18.png", width:567,height:676 }
	,{src:"des19.png", width:2202,height:1076 }
	,{src:"des20.png", width:2152,height:1336 }
	,{src:"katalog_.png", width:1402,height:1086 }
	,{src:"des_an.png", width:1920,height:1080 }
	,{src:"animals_cut.png", width:2000,height:2000 }
];

const illustrationImages = [
	{src:"ink23_all.jpg", width:2560, height:2476}
	,{src:"il1.png", width:2088, height:2016}
	,{src:"ill_.png", width:1751,height:1592}
	,{src:"il4.png", width:1609, height:600}
	,{src:"kpk2.png", width:838, height:992}
	,{src:"kpk1.png", width:838, height:992}
	,{src:"il9.png", width:2560, height:626}
	,{src:"il6.png", width:1196, height:600}
	,{src:"il7.png", width:1124, height:631}
	,{src:"il8.png", width:1246, height:600}
	,{src:"il5.png", width:1333, height:500}
	,{src:"il2.png", width:795, height:268}
	,{src:"il3.png", width:1192, height:384}
	,{src:"il10.png", width:1702, height:916}
	,{src:"char_1.png", width:1060, height:802}

];
export { designImages, illustrationImages};
