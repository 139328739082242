import './App.css';
import { Outlet } from "react-router-dom";
import intlMessagesRU from './locales/ru.json';
import intlMessagesEN from './locales/en.json';
import { useParams, useLocation } from "react-router-dom";
import {IntlProvider} from 'react-intl';
import Header from './components/Header';
import SocialIcons from './components/SocialIcons';

function App() {
  	let { lang } = useParams();
	let messages = lang==='ru'?intlMessagesRU:intlMessagesEN;
  	const location = 'home' + useLocation().pathname.substr(3);
	document.documentElement.lang = lang;
	const classNames = 'App ' + location.replaceAll('/','_');
	return (
		<IntlProvider key={ lang } locale={ lang }  messages={ messages }>
    <div className={classNames}>
		<Header/>
		<div className="socialSticker"><SocialIcons transparent={location==='home'}/></div>
		<div>
    <Outlet/>
		</div>
    </div>
		</IntlProvider>
  );
}

export default App;
