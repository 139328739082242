import { SocialIcon } from 'react-social-icons';

const SocialIcons = ({transparent}) => {
	const destinations = ["https://t.me/LexYellowglass", "mailto:me@sashulya.ru"];
  return (
    <div className="socialIcons">{
	   transparent?destinations.map( (d,i)=>(<SocialIcon key={'s'+i} url={d} fgColor="white" bgColor="transparent"/>))
	    :destinations.map( (d,i) =>(<SocialIcon key={'s'+i} url={d} fgColor="white" />))
    }</div>
  );
};

export default SocialIcons;
