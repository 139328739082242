export const languagesSupported  =[ 'en','ru' ];

export const isLanguageSupported = ( lang ) => languagesSupported.indexOf( lang )!==-1;

export const getLanguageFromLocale = ( locale ) => locale.substr(0,2);

export const getUserLanguage = () => {
	const langs = navigator.languages;
	for(let i = 0;i<langs.length;++i ) {
		let lang = getLanguageFromLocale( langs[i] );
		if ( isLanguageSupported( lang ) ) {
			return lang;
		}
	}
	return languagesSupported[0];
}
